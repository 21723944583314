import { ButtonLink, If, Link } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
const cx = withClassNames(s)
import { useFeatureFlag } from './utils'

export const YutoDetails = () => {
  const featureFlag = useFeatureFlag()
  return (
    <div className={cx('prod-tangibles', 'rainbow-background')}>
      <div className='container'>
        <h2>Yuto Horigome Pack Details</h2>
      </div>

      <div className={cx('yr-event-columns')}>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>Yuto's authentic beanie</h3>
          <p className='mb-10'>
            Yes, the actual beanie that he wore at Hollywood High has been cut
            up and put into 404 of these cards.
          </p>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>12 different 1 of 1's</h3>
          <p className='mb-10'>
            1 of 1's can only be claimed through the redemption card. Scan the card to see
            if you got it.
          </p>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>English & Japanese cards</h3>
          <p className='mb-10'>There are 3 photos in this set, each has an english and japanese card for a total of 6 different cards.</p>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>6 different rarities per card</h3>
          <p className='mb-10'>
            Each photo has 6 different rarities. Base, Rainbow Holo, Blue Lava, Red Kyber, Black Mosaic, 1 of 1 Gold Legacy.
          </p>
        </div>
      </div>
    </div>
  )
}
