import { withClassNames } from '~/styles'
import s from './roadmap.module.scss'
const cx = withClassNames(s)

export const Roadmap = () => {
  return (
    <div className={cx('roadmap-container')}>
      <div className={cx('copy-container')}>
        <h2>Upcoming projects</h2>

        <ul>
          <li>Primitive Park skate session - Invite only</li>
          <li>Marketplace (in progress)</li>
          <li>Auctions from Pro's personal collections</li>
          <li>Physical packs of cards</li>
        </ul>
      </div>
    </div>
  )
}
