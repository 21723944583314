import {
  ButtonLink,
  If,
  MailChimp,
  YutoDropDateVideo,
  YutoOutNowVideo,
} from '~/client/components'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'
import { CountdownTimer } from '../Countdown/CountDownBase'

const cx = withClassNames(s)

export const YutoHomePage = () => {
  const { copyAnimation, videoAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main', 'rainbow-background')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx(
          'header-container',
          !!featureFlag.displayVideos && 'videos-container',
        )}
      >
        <If cond={!featureFlag.displayVideos}>
          <motion.div
            variants={copyAnimation}
            className={cx('copy', 'centered')}
          >
            <h2 className='margin-10'>Yuto Horigome Boxes & Packs</h2>
            <p>
              A chance to pull autographed cards and the authentic piece from
              Yuto's beanie from Hollywood High
            </p>
            <If cond={!featureFlag.yutoLive}>
              <h3 className="margin-20">Drops TODAY at 6pm PST</h3>
              <CountdownTimer countdownDate={'May 3, 2024 17:00:00 GMT-0800'}/>
              <If cond={!featureFlag.yutoLoading}>
                <div className='yuto-mailchimp'>
                  <MailChimp />
                </div>
              </If>
              <If cond={featureFlag.yutoLoading}>
                <button disabled className='outline margin-top-20'>
                  <p>I will automatically turn into a button at 6pm PST</p>
                  <div className='loading-dots'></div>
                </button>
              </If>
            </If>
            <If cond={featureFlag.yutoLive}>
              <h3 className="margin-top-20">OUT NOW!</h3>
              <ButtonLink className="margin-top-20 pulsingButton" href={'https://store.abdcollectibles.com/products/yuto-packs'}>Shop now</ButtonLink>
            </If>
          </motion.div>

          <motion.section variants={videoAnimation}>
            <If cond={!featureFlag.yutoLive}>
              <YutoDropDateVideo />
            </If>
            <If cond={featureFlag.yutoLive}>
              <YutoOutNowVideo />
            </If>
          </motion.section>
        </If>

        <If cond={featureFlag.displayVideos}>
          <motion.section variants={videoAnimation}>
            <YouTubeEmbed />
          </motion.section>
          <motion.section variants={videoAnimation}>
            <TwitchEmbed />
          </motion.section>
        </If>
      </motion.div>
    </div>
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const YouTubeEmbed = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/PyLOk8i6gNw'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const TwitchEmbed = () => (
  <div className={cx('twitch-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src={
        'https://player.twitch.tv/?channel=abdcollectibles&parent=' +
        window.location.hostname
      }
      frameBorder='0'
      allowFullScreen
      scrolling='no'
    ></iframe>
  </div>
)
