import {
  AndyTrailer,
  If,
  MailChimp,
  MyspaceOnlineNow,
} from '~/client/components'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'
import {
  CountdownTimer,
  CountdownTimerHoursOnly,
} from '../Countdown/CountDownBase'

const cx = withClassNames(s)

export const AndyVideoHeader = () => {
  const { copyAnimation, videoAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()
  const ffAndyNotLive =
    !featureFlag.andyLiveStream &&
    !featureFlag.andyMainPart &&
    !featureFlag.andySkateTales
  const ffAndyLiveStream =
    featureFlag.andyLiveStream &&
    !featureFlag.andyMainPart &&
    !featureFlag.andySkateTales
  const ffAndyMainPart =
    featureFlag.andyMainPart &&
    featureFlag.andyLiveStream &&
    !featureFlag.andySkateTales
  const ffAndySkateTales =
    featureFlag.andySkateTales &&
    featureFlag.andyMainPart &&
    featureFlag.andyLiveStream

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx(
          'header-container',
          !!featureFlag.displayVideos && 'videos-container',
        )}
      >
        <If cond={!featureFlag.andyDayOf}>
          <motion.div variants={copyAnimation} className={cx('copy', 'center')}>
            <h1 className='medium-h1'>ANDY ANDERSON'S</h1>
            <h2>"CRAZY WISDOM"</h2>
            <p>Premiere event starts July 6th, at 6pm PT</p>
            <CountdownTimer countdownDate={'July 6, 2024 18:00:00 GMT-0700'} />
            <MailChimp />
          </motion.div>

          <motion.section variants={videoAnimation}>
            <AndyTrailer />
          </motion.section>
        </If>

        <If cond={featureFlag.andyDayOf && !featureFlag.andyLiveStream}>
          <motion.div variants={copyAnimation} className={cx('copy', 'center')}>
            <h1 className='medium-h1'>ANDY ANDERSON'S</h1>
            <h2 className='mb-10'>"CRAZY WISDOM"</h2>
            <CountdownTimerHoursOnly
              countdownDate={'July 6, 2024 18:00:00 GMT-0700'}
            />
            <h3>Premiere schedule:</h3>
            <p>Live Pre-show, 6pm PT</p>
            <p>Crazy Wisdom Full Part, 7pm PT</p>
            <p className='mb-10'>
              Red Bull's Skate Tales feat. Andy Anderson, 7:20pm PT
            </p>
            <MailChimp />
          </motion.div>

          <motion.section variants={videoAnimation}>
            <If cond={!featureFlag.andyLiveStream}>
              <AndyTrailer />
            </If>
            <If cond={featureFlag.andyLiveStream}>
              <LiveStream />
            </If>
          </motion.section>
        </If>

        <If cond={ffAndyLiveStream}>
          <motion.div
            variants={copyAnimation}
            className={cx('copy', 'centered')}
          >
            <div className='for-desktop'>
              <h1 className='medium-h1'>ANDY ANDERSON'S</h1>
              <h2 className='mb-10'>"CRAZY WISDOM"</h2>
              <div className='pre-show-container'>
                <span className='myspace-small'>
                  <MyspaceOnlineNow />
                </span>
                <p className='pre-show-text'>PRE-SHOW NOW LIVE</p>
              </div>
            </div>
            <h3>NEXT UP:</h3>
            <p>Crazy Wisdom Full Part, 7pm PT</p>
            <p className='mb-10'>
              Red Bull's Skate Tales feat. Andy Anderson, 7:20pm PT
            </p>
            <CountdownTimerHoursOnly
              countdownDate={'July 6, 2024 19:00:00 GMT-0700'}
            />
          </motion.div>

          <motion.section variants={videoAnimation}>
            <If cond={!ffAndyLiveStream}>
              <AndyTrailer />
            </If>
            <div className='for-mobile centered'>
              <h1 className='medium-h1'>ANDY ANDERSON'S CRAZY WISDOM</h1>
              <div className='pre-show-container'>
                <span className='myspace-small'>
                  <MyspaceOnlineNow />
                </span>
                <p className='pre-show-text'>PRE-SHOW NOW LIVE</p>
              </div>
            </div>
            <div className='andy-premiere-embed mt-10'>
              <LiveStream />
            </div>
          </motion.section>
        </If>

        <If cond={ffAndyMainPart}>
          <motion.div
            variants={copyAnimation}
            className={cx('copy', 'centered')}
          >
            <div className='for-desktop'>
              <h1 className='medium-h1'>ANDY ANDERSON'S</h1>
              <h2 className='mb-10'>"CRAZY WISDOM"</h2>
              <div className='pre-show-container'>
                <span className='myspace-small'>
                  <MyspaceOnlineNow />
                </span>
                <p className='pre-show-text'>FULL PART NOW LIVE</p>
              </div>
            </div>
          </motion.div>

          <motion.section variants={videoAnimation}>
            <If cond={!featureFlag.andyLiveStream}>
              <AndyTrailer />
            </If>

            <div className='for-mobile centered'>
              <h1 className='medium-h1'>ANDY ANDERSON'S CRAZY WISDOM</h1>
              <div className='pre-show-container'>
                <span className='myspace-small'>
                  <MyspaceOnlineNow />
                </span>
                <p className='pre-show-text'>FULL PART NOW LIVE</p>
              </div>
            </div>
            <div className='andy-premiere-embed mt-10'>
              <AndyMainPartCrazyWisdom />
            </div>
          </motion.section>
        </If>

        <If cond={ffAndySkateTales}>
          <If cond={!featureFlag.andyEverythingIsDone}>
            <motion.section variants={videoAnimation}>
              <div className='centered'>
                <h3 className='medium-h1'>ANDY ANDERSON'S CRAZY WISDOM</h3>
                <p>OUT NOW</p>
              </div>
              <AndyMainPartCrazyWisdom />
            </motion.section>

            <motion.section variants={videoAnimation}>
              <div className='centered'>
                <h1 className='medium-h1'>RED BULL'S SKATE TALES</h1>
                <p>FEATURING ANDY ANDERSON</p>
                <div className='for-mobile'>
                  <CountdownTimerHoursOnly
                    countdownDate={'July 6, 2024 19:20:00 GMT-0700'}
                  />
                </div>
              </div>
              <AndySkateTales />
            </motion.section>
          </If>

          <If cond={featureFlag.andyEverythingIsDone}>
            <motion.section variants={videoAnimation}>
              <div className='centered'>
                <h1 className='medium-h1'>RED BULL'S SKATE TALES</h1>
                <p>FEATURING ANDY ANDERSON</p>
              </div>
              <AndySkateTales />
            </motion.section>

            <motion.section variants={videoAnimation}>
              <div className='centered'>
                <h3 className='medium-h1'>ANDY ANDERSON'S CRAZY WISDOM</h3>
                <p>OUT NOW</p>
              </div>
              <AndyMainPartCrazyWisdom />
            </motion.section>
          </If>
        </If>
      </motion.div>
    </div>
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const LiveStream = () => (
  <div className={cx('andy-premiere-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/OTe41nA2uMg?si=OLrpyz4F55q9HsV0'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const AndyMainPartCrazyWisdom = () => (
  <div className={cx('andy-premiere-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/vRj11yZO9Ck?si=TVZbTOJvTxJFugTl'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const AndySkateTales = () => (
  <div className={cx('andy-premiere-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/wKjzij5DP7E?si=TfxRuB3rRMYGRiks'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const TwitchEmbed = () => (
  <div className={cx('twitch-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src={
        'https://player.twitch.tv/?channel=abdcollectibles&parent=' +
        window.location.hostname
      }
      frameBorder='0'
      allowFullScreen
      scrolling='no'
    ></iframe>
  </div>
)
