import { AndyAuctionDarkslide, AndyBox, AndyPack, ButtonLink, If } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
import { useFeatureFlag } from './utils'
const cx = withClassNames(s)

export const AndyProductColumns = () => {
  const featureFlag = useFeatureFlag()
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <h2>Andy Anderson Autographed<br></br>Collectibles from "Crazy Wisdom"</h2>
      <div className={cx('tangible-items-2')}>
        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <a href="https://store.abdcollectibles.com/products/andy-anderson-series-4">
              <AndyPack />
            </a>
          </div>
          <h3>
            <strong>Collectible Packs</strong>
          </h3>
          <p className='margin-10 bold'>
            Contains 2 cards and 1 lottery card
          </p>
          <h4 className='margin-bottom-20'>Starting at $12.96</h4>
          <If cond={!featureFlag.andyLiveStream}>
            <ButtonLink href={'https://store.abdcollectibles.com/products/andy-anderson-series-4'} className='small-h1'>July 6th, 6pm PT</ButtonLink>
          </If>
          <If cond={featureFlag.andyLiveStream}>
            <If cond={!featureFlag.andySoldOut}>
              <ButtonLink href={'https://store.abdcollectibles.com/products/andy-anderson-series-4'} className='small-h1 pulsingButton'>Shop Now</ButtonLink>
            </If>
            <If cond={featureFlag.andySoldOut}>
              <ButtonLink href={'https://store.abdcollectibles.com/products/andy-anderson-series-4'} className='small-h1'>SOLD OUT</ButtonLink>
            </If>
          </If>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <If cond={!featureFlag.andyLiveStream}>
              <AndyAuctionDarkslide />
            </If>
            <If cond={featureFlag.andyLiveStream}>
              <a href='https://store.abdcollectibles.com/products/andy-anderson-darkslide-complete-auction'>
                <AndyAuctionDarkslide />
              </a>
            </If>
          </div>
          <h3>
            <strong>Skateboard Auction</strong>
          </h3>
          <p className='margin-10 bold'>
            The skated complete from Andy's darkslide
          </p>
          <h4 className='margin-bottom-20'>Sold for $1,895</h4>
          <If cond={!featureFlag.andyLiveStream}>
            <ButtonLink href={''} className='small-h1'>July 6th, 6pm PT</ButtonLink>
          </If>
          <If cond={featureFlag.andyLiveStream}>
            <ButtonLink href={'https://store.abdcollectibles.com/products/andy-anderson-darkslide-complete-auction'} className='small-h1 pulsingButton'>Shop Now</ButtonLink>
          </If>
        </div>
      </div>
    </div>
  )
}
