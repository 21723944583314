import { ButtonLink, If, MailChimp, TheBerricsTrailer } from '~/client/components'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'
import { Countdown } from '../Countdown/CountDownBase'

const cx = withClassNames(s)

export const HomePageMikemoLastBoard = () => {
  const { copyAnimation, videoAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main', 'mikemo-last-board-background')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx(
          'header-container', 'mobile-padding-top'
        )}
      >

          <If cond={!featureFlag.mikemoLastBoard && !featureFlag.earlyAccess}>
            <motion.div variants={copyAnimation} className={cx('copy', 'centered')}>
              <h1>Mikemo's Last Board</h1>
              <p>(Blind Bag with 6 different rarities)</p>
              <h4 className='centered margin-10 bold'>Pre-orders start on August 20th.<br></br>Exactly 10 years after his injury.</h4>
              <Countdown countdownDate={'August 20, 2024 11:00:00 GMT-0800'}/>
              <p>Sign up to get notified</p>
              <MailChimp />
            </motion.div>
          </If>

          <If cond={!featureFlag.earlyAccess && featureFlag.mikemoLastBoard}>
            <motion.div variants={copyAnimation} className={cx('copy', 'centered')}>
              <p>Exactly 10 years after the injury...</p>
              <h1>Mikemo's Last Board</h1>
              <p className='margin-10'>Blind Bag, Autographed, 6 Different Rarities</p>
              <p className='mb-20'>Pre-orders end Sep 3rd</p>
              <ButtonLink href={'https://store.abdcollectibles.com/products/mikemos-last-board'}>Shop Last Board</ButtonLink>
            </motion.div>
          </If>

          <If cond={featureFlag.earlyAccess}>
            <motion.div variants={copyAnimation} className={cx('copy', 'centered')}>
              <h1>Mikemo's Last Board</h1>
              <p className='margin-20'>SERIES 1, 2, 3, OWNERS HAVE EXCLUSIVE ACCESS TO PRE-ORDER THE GOLD 2024 DECK</p>
              <p>Exclusive access ends in</p>
              <div className='mb-20'>
              <Countdown countdownDate={'August 21, 2024 11:00:00 GMT-0800'}/>
              </div>
              <ButtonLink className='shiny-gold-button mb-10' href={'https://store.abdcollectibles.com/products/mikemos-last-board-gold-board-only'}>Shop Gold Board</ButtonLink>
              <ButtonLink href={'https://store.abdcollectibles.com/products/mikemos-last-board'}>Shop Blind Bag</ButtonLink>
            </motion.div>
          </If>

          <motion.section variants={videoAnimation}>
           
          </motion.section>
      </motion.div>
    </div>

    
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const YouTubeEmbed = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/PyLOk8i6gNw'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const TwitchEmbed = () => (
  <div className={cx('twitch-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src={
        'https://player.twitch.tv/?channel=abdcollectibles&parent=' +
        window.location.hostname
      }
      frameBorder='0'
      allowFullScreen
      scrolling='no'
    ></iframe>
  </div>
)
