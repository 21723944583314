import { ButtonLink, If, Link } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
const cx = withClassNames(s)
import { useFeatureFlag } from './utils'

export const AprilFoolsDetails = () => {
  const featureFlag = useFeatureFlag()
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div className='container'>
        <h2>
          Garrett Hill AF Card Details
        </h2>
      </div>

      <div className={cx('yr-event-columns')}>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>AF #1</h3>
          <p className='mb-10'>Garrett Hill's famous coffee slam will set off a new collection. Every year, expect another fun moment from skateboarding history.</p>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>How to enter</h3>
          <p className='mb-10'>Owners of any Series 1, 2, or 3, digital moments will be automatically entered to win the first-ever AF card. (Each moment counts as an entry.)</p>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>50 winners</h3>
          <p className='mb-10'>We will randomly draw 50 winners, live on stream with Garrett Hill on April 6th at 4pm PST.</p>
            <Link className="margin-10 link-color" href={'https://youtube.com/live/23_LgDH_KkQ?feature=share'}>Watch live stream</Link>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>Non-authentic cup</h3>
          <p className='mb-10'>Did you really think he kept the cup from his Coffee Slam? Yeah, theres no way. But we can have fun with it and pretend he did.</p>
        </div>
      </div>
    </div>

    
  )
}
