import { ButtonLink, If, MailChimp, YeahRightVideoSummary } from '~/client/components'
import { motion } from 'framer-motion'
import { withClassNames } from '~/styles'
import s from './lock.module.scss'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import {
  BounceInOutAnimation,
  HeaderAnimationVariants,
  SlideInFromRightAnimation,
  SlideInFromLeftAnimation,
} from '~/client/components/Animations'
import { useFeatureFlag } from './utils'

const cx = withClassNames(s)

export const YeahRightVideoBanner = () => {
  const { copyAnimation, videoAnimation } = useAnimations()
  const featureFlag = useFeatureFlag()

  if (copyAnimation === null) return null

  return (
    <div className={cx('header-main', 'yeah-right-background')}>
      <motion.div
        variants={HeaderAnimationVariants}
        className={cx(
          'header-container',
          !!featureFlag.displayVideos && 'videos-container',
        )}
      >
          <motion.div variants={copyAnimation} className={cx('copy')}>
            <h1 className='yr-header margin-10'>Watch the 20 year anniversary of Yeah Right</h1>
            <p className='yr-font margin-top-10 centered'>Eric Koston, Paul Rodriguez, Brian Anderson, Jeron Wilson, Rick Howard & Mike Carroll, all together again!</p>
            <If cond={!featureFlag.yrLive}>
              <MailChimp />
            </If>
          </motion.div>

          <motion.section variants={videoAnimation}>
            <YouTubeEmbed />
          </motion.section>
      </motion.div>
    </div>
  )
}

function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}

const YouTubeEmbed = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/Xl432_Jye4Y?si=4Msk2ezlwA1kTPqc'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)