import { ButtonLink, If, YutoBox, YutoPack } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
import { useFeatureFlag } from './utils'
const cx = withClassNames(s)

export const ProductColumnsTwo = () => {
  const featureFlag = useFeatureFlag()
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div className={cx('tangible-items-2')}>
        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <If cond={!featureFlag.yutoLive}>
              <YutoBox />
            </If>
            <If cond={featureFlag.yutoLive}>
              <a href='https://store.abdcollectibles.com/products/yuto-packs'>
                <YutoBox />
              </a>
            </If>
          </div>
          <h3>
            <strong>Box (10 packs)</strong>
          </h3>
          <p className='margin-10 bold'>
            Each box guarantees an autographed card
          </p>
          <h4 className='margin-bottom-20'>$126.90</h4>
          <If cond={featureFlag.yutoLoading && !featureFlag.yutoLive}>
            <button disabled className='outline'>
              <p>Drops at 6pm PST</p>
              <span className='loading-dots'></span>
            </button>
          </If>
          <If cond={featureFlag.yutoLive}>
            <ButtonLink className='pulsingButton'
              href={'https://store.abdcollectibles.com/products/yuto-packs'}
            >
              Shop now
            </ButtonLink>
          </If>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <If cond={!featureFlag.yutoLive}>
              <YutoPack />
            </If>
            <If cond={featureFlag.yutoLive}>
              <a href='https://store.abdcollectibles.com/products/yuto-packs?variant=44092579643561'>
                <YutoPack />
              </a>
            </If>
          </div>
          <h3>
            <strong>Single Pack</strong>
          </h3>
          <p className='margin-10 bold'>
            Contains 2 cards and 1 redemption card
          </p>
          <h4 className='margin-bottom-20'>Starting at $12.69</h4>
          <If cond={featureFlag.yutoLoading && !featureFlag.yutoLive}>
            <button disabled className='outline'>
              <p>Drops at 6pm PST</p>
              <span className='loading-dots'></span>
            </button>
          </If>
          <If cond={featureFlag.yutoLive}>
            <ButtonLink className='pulsingButton'
              href={
                'https://store.abdcollectibles.com/products/yuto-packs?variant=44092579643561'
              }
            >
              {' '}
              Shop now
            </ButtonLink>
          </If>
        </div>
      </div>
    </div>
  )
}
