import { ButtonLink, If, Link } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
const cx = withClassNames(s)
import { useFeatureFlag } from './utils'

export const BerricsCubeFeatures = () => {
  const featureFlag = useFeatureFlag()
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div className='container'>
        <h2>
          Berrics Farewell Cube Features
        </h2>
      </div>

      <div className={cx('yr-event-columns')}>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>See where your cement comes from</h3>
          <p className='mb-10'>Scan the QR code on your cube and it will show you exactly where it came from in the park, and a video of all the highlights from that spot.</p>
            <Link className="margin-10 link-color" href={'/berrics-cube'}>View Spots</Link>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>Golden ticket to BATB 14</h3>
          <br></br>
          <p className='mb-10'>Every pre-order will have the chance to get a free flight and hotel to BATB 14 Finals Night.<br></br><br></br></p>
            <Link className="margin-10 link-color" href={'https://store.abdcollectibles.com/products/berrics-memorabilia'}>Pre-order</Link>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>Signed by the pros</h3>
          <br></br>
          <p className='mb-10'>Each cube will include a different signature on the cement. It could be a signature from Malto, Berra, Joslin, Koston, Bam, etc...<br></br><br></br> </p>
          <Link className="margin-10 link-color" href={'https://store.abdcollectibles.com/products/berrics-memorabilia'}>Pre-order</Link>
        </div>
        <div className={cx('tangible-item')}>
          <h3 className='nomargin'>Full-Length Berrics Story</h3>
          <br></br>
          <p className='mb-10'>A documentary about the history of the Berrics, and how large of an impact it has had on the skateboarding community. (Coming soon)<br></br></p>
          <Link className="margin-10 link-color" href={'https://store.abdcollectibles.com/products/berrics-memorabilia'}>Pre-order</Link>
        </div>
      </div>
      
    </div>

    
  )
}
