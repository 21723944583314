import { ButtonLink, JamieFoyCards, JawsCards, JulianRookieCard, TonyCards, YeahRightPackaging, YeahRightSoldOut } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
import { useFeatureFlag } from './utils'
const cx = withClassNames(s)

export const ProductColumns = () => {
  
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div className='container'>
        <h2>
          Physical collectibles
        </h2>
        <p>Each card comes in a sealed box, with a different serial number and rarity type. <br></br>Once they have sold out,
          they will never be reprinted.
        </p>
      </div>

      <div className={cx('tangible-items')}>
        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <a href='https://store.abdcollectibles.com/products/last-900' >
              <TonyCards type='GoldLegacy' />
            </a>
          </div>
          <h3>
            <strong>Tony Hawk's Last 900</strong>
          </h3>
          <p className='margin-10 bold'>Signed, numbered and includes a piece of Tony's Ramp</p>
          <h4 className='margin-bottom-20'>$199.69</h4>
          <ButtonLink href={'https://store.abdcollectibles.com/products/last-900'}>Sold out</ButtonLink>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <a href='https://store.abdcollectibles.com/products/jamie-foy-at-hollywood-high-16' >
              <JamieFoyCards type='GoldLegacy' />
            </a>
          </div>
          <h3>
            <strong>Jamie Foy at Hollywood High</strong>
          </h3>
          <p className='margin-10 bold'>Signed, numbered and includes a piece of Jamie's truck</p>
          <h4 className='margin-bottom-20'>$169.69</h4>
          <ButtonLink href={'https://store.abdcollectibles.com/products/jamie-foy-at-hollywood-high-16'}>Shop now</ButtonLink>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <a href='https://store.abdcollectibles.com/products/jaws-at-el-toro' >
              <JawsCards type='GoldLegacy' />
            </a>
          </div>
          <h3>
            <strong>Jaws at El Toro</strong>
          </h3>
          <p className='margin-10 bold'>Signed and numbered by Aaron "Jaws" Homoki</p>
          <h4 className='margin-bottom-20'>$129.69</h4>
          <ButtonLink href={'https://store.abdcollectibles.com/products/jaws-at-el-toro'}>Shop now</ButtonLink>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <a href='https://store.abdcollectibles.com/products/jaws-at-el-toro' >
              <YeahRightSoldOut />
            </a>
          </div>
          <h3>
            <strong>YEAH RIGHT</strong>
          </h3>
          <p className='margin-10 bold'>Celebrating 20 years of Yeah Right!</p>
          <h4 className='margin-bottom-20'>$149.69</h4>
          <ButtonLink href={'https://store.abdcollectibles.com/products/yeah-right'}>Sold out</ButtonLink>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <a href='https://store.abdcollectibles.com/products/jaws-at-el-toro' >
              <JulianRookieCard />
            </a>
          </div>
          <h3>
            <strong>Julian Jeang-Agliardi</strong>
          </h3>
          <p className='margin-10 bold'>First-ever Rookie card</p>
          <h4 className='margin-bottom-20'>$99.95</h4>
          <ButtonLink href={'https://store.abdcollectibles.com/products/yeah-right'}>Shop now</ButtonLink>
        </div>

      </div>
    </div>
  )
}
