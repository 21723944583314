import { ButtonLink, JamieFoyCards, JawsCards, MikemoLegendaryRefractorStand, ProdCards, TonyCards, ToreyLegendaryRefractorStand } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
const cx = withClassNames(s)

export const ProductColumnsAlternate = () => {
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div className="container">
        <h2>
          Pull Physical Collectibles out of Digital Packs
        </h2>
        <p>Digital packs contain the chance of pulling <strong className='legendary-text'>Legendary </strong> 
        moments. If a <strong className='legendary-text'>Legendary</strong> is pulled, we will ship
          the physical card to you for free.
        </p>
      </div>

      <div className={cx('tangible-items-3')}>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <a href='/packs' >
              <ProdCards type='prodLegendaryRefractorStand' />
            </a>
          </div>
          <h3>
            <strong>Paul Rodriguez in "What The Prod!?"</strong>
          </h3>
          <p className='margin-10 bold'>**Pull a <strong className='legendary-text'>Legendary </strong> and unlock this physical card**</p>
          <h4 className='margin-bottom-20'>Starting at $14.69</h4>
          <ButtonLink href={'/packs'}>Shop Packs</ButtonLink>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <ToreyLegendaryRefractorStand />
          </div>
          <h3>
            <strong>Torey Pudwill in "Bigger Bang"</strong>
          </h3>
          <p className='margin-10 bold'>**Pull a <strong className='legendary-text'>Legendary </strong> and unlock this physical card**</p>
          <h4 className='margin-bottom-20'>Starting at $12.69</h4>
          <button disabled>Temporarily Unavailable</button>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <MikemoLegendaryRefractorStand />
          </div>
          <h3>
            <strong>Mikemo Capaldi in Fully Flared</strong>
          </h3>
          <p className='margin-10 bold'>**Pull a <strong className='legendary-text'>Legendary </strong> and unlock this physical card**</p>
          <h4 className='margin-bottom-20'>Starting at $9.69</h4>
          <button disabled>Sold out forever</button>
        </div>
      </div>
    </div>
  )
}
