import { GradientBox, If, MailChimp } from 'components'
import { cond } from 'lodash'
import { useCountdown } from '~/client/utils/utils'
import { withClassNames } from '~/styles'
import { ButtonLink } from '../Buttons'
import { SealedAndRevealed } from '../Lock/SealedAndRevealed'
import s from './countdown.module.scss'
const cx = withClassNames(s)

export const countdownDate = 'October 30 2022 17:00:00 GMT-0800'

export const useCountDownTimer = () => {
  return useCountdown(countdownDate)
}

export const Countdown = (props: Partial<ICountdown> = { countdownDate }) => (
  <CountdownTimer countdownDate={props.countdownDate ?? countdownDate} />
)

export const CountdownTimer = ({ countdownDate }: ICountdown) => {
  const { days, hours, minutes, seconds, isPastTime } = useCountdown(
    new Date(countdownDate).getTime(),
  )

  if (isPastTime) {
    return (
          null  
    )
  }

  return (
    <div className={cx('countdown-container')}>
      {/* <GradientBox> */}
      <div className={cx('number-container')}>
        <div>
          <span className={cx('number')}>{days}</span>
          <span>days</span>
        </div>
        <div>
          <span className={cx('number')}>{hours}</span>
          <span>hours</span>
        </div>
        <div>
          <span className={cx('number')}>{minutes}</span>
          <span>minutes</span>
        </div>
        <div>
          <span className={cx('number')}>{seconds}</span>
          <span>seconds</span>
        </div>
      </div>
      {/* </GradientBox> */}
    </div>
  )
}

export const CountdownTimerHoursOnly = ({ countdownDate }: ICountdown) => {
  const { hours, minutes, seconds, isPastTime } = useCountdown(
    new Date(countdownDate).getTime(),
  )

  if (isPastTime) {
    return (
          null  
    )
  }

  return (
    <div className={cx('countdown-container')}>
      {/* <GradientBox> */}
      <div className={cx('number-container')}>
        <div>
          <span className={cx('number')}>{hours}</span>
          <span>hours</span>
        </div>
        <div>
          <span className={cx('number')}>{minutes}</span>
          <span>minutes</span>
        </div>
        <div>
          <span className={cx('number')}>{seconds}</span>
          <span>seconds</span>
        </div>
      </div>
      {/* </GradientBox> */}
    </div>
  )
}

export interface ICountdown {
  countdownDate: string
}

// TODO: move this elsewhere, just need this file's styles since I sux
export const SoldOutGradientBox = () => {
  return (
    <div className={cx('countdown-container')}>
      <GradientBox>
        <div className={cx('heading', 'rainbow-text', 'no-margin')}>
          <div>Sold Out!</div>
        </div>
      </GradientBox>
    </div>
  )
}

export const AllPacksExpireBox = () => {
  const data = {
    main: 'Physical & digital collectibles',
    button: 'View Packs',
  }

  return (
    <div className={cx('countdown-container')}>
      <GradientBox>
        <div className={cx('heading', 'rainbow-text', 'no-margin')}>
          <div>{data.main}</div>
          <ButtonLink
            href={'/packs'}
            buttonProps={{
              className: cx('shop-now'),
            }}
          >
            {data.button}
          </ButtonLink>
        </div>
      </GradientBox>
    </div>
  )
}

// TODO: move this elsewhere as well
export const AvailableText = () => {
  return (
    <div className={cx('countdown-container')}>
      <GradientBox>
        <div className={cx('heading', 'rainbow-text', 'no-margin')}>
          <div>Available July 8th</div>
        </div>
      </GradientBox>
    </div>
  )
}

const placeInLine = Math.floor(Math.random() * 50) + 15
export const PleaseWait = () => {
  return (
    <div className={cx('countdown-container')}>
      <GradientBox>
        <div className={cx('heading', 'rainbow-text', 'no-margin')}>
          <div>You are {placeInLine} in line. Please wait a moment.</div>
          <p>If you refresh you may lose your place in line.</p>
        </div>
      </GradientBox>
    </div>
  )
}

export const MailChimpCountdown = () => {
  return (
    <div className={cx('countdown-container', 'pack-type-container')}>
      <h3 className='center'>Available Oct 21st at 7pm PDT</h3>
      <Countdown />
      <MailChimp />
    </div>
  )
}

export const CountdownHours = ({ countdownDate }: ICountdown) => {
  const { hours, isPastTime } = useCountdown(
    new Date(countdownDate).getTime(),
  )

  if (isPastTime) {
    return (
          null  
    )
  }

  return (
    <span className={cx('number')}>{hours}</span>
  )
}
