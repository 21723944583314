import { Roadmap } from '.'
import { PageTransitions } from '../components/Animations'
import s from './../../client/components/Tangibles/tangibles.module.scss'
import { withClassNames } from '~/styles'
import { If } from '../components'
import { useFeatureFlag } from '../components/Lock/utils'
import { ProductColumns } from '../components/Lock/ProductColumns'
import { ProductColumnsAlternate } from '../components/Lock/ProductColumnsAlternate'
import { VideoColumns } from '../components/Lock/VideoColumns'
import { YeahRightVideoBanner } from '../components/Lock/YeahRightVideoBanner'
import { HomePageBerrics } from '../components/Lock/HomePageBerrics'
import { BerricsCubeFeatures } from '../components/Lock/BerricsCubeFeatures'
import { HomePageJeron } from '../components/Lock/HomePageJeron'
import { AprilFoolsDetails } from '../components/Lock/AprilFoolsDetails'
import { HomePageGarrettLiveStream } from '../components/Lock/HomePageGarrettLiveSteram'
import { YutoDetails } from '../components/Lock/YutoDetails'
import { YutoHomePage } from '../components/Lock/YutoHomePage'
import { ProductColumnsTwo } from '../components/Lock/ProductColumnsTwo'
import { AndyProductColumns } from '../components/Lock/AndyProductColumns'
import { AndyVideoHeader } from '../components/Lock/AndyVideoHeader'
import { HomePageMikemoLastBoard } from '../components/Lock/HomePageMikemoLastBoard'
export const cx = withClassNames(s)

export const Home = () => {
  const featureFlag = useFeatureFlag()
  return (
    <PageTransitions>
      <HomePageMikemoLastBoard />
      <AndyVideoHeader />
      <hr></hr>
      <AndyProductColumns />
      <br></br>
      <br></br>
      <YutoHomePage />
      <ProductColumnsTwo />
      <YutoDetails />
      <br></br>
      <br></br>
      <br></br>
      <HomePageJeron />
      <br></br>
      <br></br>
      <br></br>
      <HomePageGarrettLiveStream />
      <AprilFoolsDetails />
      <br></br>
      <br></br>
      <br></br>
      <HomePageBerrics />
      <BerricsCubeFeatures />
      <br></br>
      <br></br>
      <br></br>
      <YeahRightVideoBanner />
      <br></br>
      <br></br>
      <br></br>
      <ProductColumns />
      <div className='margin-bottom-20' />
      <ProductColumnsAlternate />
      <Roadmap />
      <VideoColumns />
    </PageTransitions>
  )
}
