import { BounceInOutAnimation, ButtonLink, JamieFoyCards, JawsCards, MikemoLegendaryRefractorStand, ProdCards, SlideInFromLeftAnimation, SlideInFromRightAnimation, TonyCards, TonyHawkVaulted, ToreyLegendaryRefractorStand } from 'components'
import { withClassNames } from '~/styles'
import s from './lock-tangibles.module.scss'
import { motion } from 'framer-motion'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
const cx = withClassNames(s)

export const VideoColumns = () => {
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div className='container'>
        <h2>
          Moments in Skateboarding History
        </h2>
      </div>

      <div className={cx('tangible-videos')}>

        <div className={cx('tangible-item', 'margin-tb-40')}>
          <div className={cx('media-container')}>
            <YouTubeMikemo />
          </div>
          <p>Series #1</p>
          <h3>
            <strong>Mikemo in Fully Flared</strong>
          </h3>
          <h4 className='margin-bottom-20'>Behind the scenes of the switch fip explosion.</h4>
        </div>

        <div className={cx('tangible-item', 'margin-tb-40')}>
          <div className={cx('media-container')}>
            <YouTubeProd />
          </div>
          <p>Series #2</p>
          <h3>
            <strong>Paul Rodriguez in "What The Prod!?"</strong>
          </h3>
          <h4 className='margin-bottom-20'>The story about P-Rod's video part and his iconic shoe</h4>
        </div>

        <div className={cx('tangible-item', 'margin-tb-40')}>
          <div className={cx('media-container')}>
            <YouTubeTorey />
          </div>
          <p>Series #3</p>
          <h3>
            <strong>Torey Pudwill in "Bigger Bang"</strong>
          </h3>
          <h4 className='margin-bottom-20'>One of the gnarlist video parts of all time.</h4>
        </div>

        <div className={cx('tangible-item', 'margin-tb-40')}>
          <div className={cx('media-container')}>
            <YouTubeTonyHawk />
          </div>
            <p>Quickstrike #1</p>
          <h3>
            <strong>Tony Hawk's Last 900</strong>
          </h3>
          <h4 className='margin-bottom-20'>The legendary trick that changed skating forever.</h4>
        </div>

        <div className={cx('tangible-item', 'margin-tb-40')}>
          <div className={cx('media-container')}>
            <YouTubeJaws />
          </div>
          <p>Quickstrike #2</p>
          <h3>
            <strong>Jaws at El Toro</strong>
          </h3>
          <h4 className='margin-bottom-20'>Kickflip Melon down a 20 stair.</h4>
        </div>

        <div className={cx('tangible-item', 'margin-tb-40')}>
          <div className={cx('media-container')}>
            <YouTubeTorey />
          </div>
          <p>Quickstrike #3</p>
          <h3>
            <strong>Jamie Foy at Hollywood High</strong>
          </h3>
          <h4 className='margin-bottom-20'>An iconic trick and an iconic spot.</h4>
        </div>

      </div>
    </div>
  )
}


function useAnimations() {
  const copyAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    tablet: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
  })

  const videoAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    tablet: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
  })
  return { copyAnimation, videoAnimation }
}


const YouTubeJamie = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/PyLOk8i6gNw'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const YouTubeJaws = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/sTL9FHcKN0U'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const YouTubeTonyHawk = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/qF0AT4k15ho'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const YouTubeProd = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/tfBfuFiVgLc'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const YouTubeMikemo = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/08HQbYaa1gM'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)

const YouTubeTorey = () => (
  <div className={cx('youtube-embed')}>
    <iframe
      style={{ height: '100%', width: '100%' }}
      src='https://www.youtube.com/embed/0kKJXZ4Ufk4'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  </div>
)